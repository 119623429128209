import React from "react";

import { Button, Col, Row } from "antd";
import Helmet from "react-helmet";
import * as styles from "./index.module.scss";

import { APILoader, Map, Marker } from "@uiw/react-baidu-map";

import Footer from "@components/footer";
import Header from "@components/header";
import TextCenterBanner from "@components/textCenterBanner";

import pcImg from "@src/images/aboutMe/banner1.png";
import phoneImg from "@src/images/aboutMe/banner2.png";

import aboutImg1 from "@src/images/aboutMe/01.png";
import aboutImg2 from "@src/images/aboutMe/02.png";
import aboutImg3 from "@src/images/aboutMe/03.png";

import clockIcon from "@src/images/aboutMe/clock.png";
import mailIcon from "@src/images/aboutMe/mail.png";

import typePhoneNumberPhoneImg from "@src/images/aboutMe/飞书20210918-142421.png";
import typePhoneNumberPcImg from "@src/images/aboutMe/飞书20210918-142430.png";
import { navigate } from "gatsby";
const handleRegister = (event) => {
  event.preventDefault();
  navigate("/register/");
};

const isBrowser = typeof window !== "undefined";

const AboutMePage = () => {
  return (
    <div className={styles.aboutMePage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>关于我们-Shoprise 官网</title>
      </Helmet>
      <Header />
      <TextCenterBanner
        pcImg={pcImg}
        phoneImg={phoneImg}
        text={"我们全力以赴，只为合作共赢"}
      />
      <div className={styles.contentContainer}>
        <Row>
          {/* Pc端 */}
          <Col xs={0} sm={0} md={24}>
            <div className={styles.aboutContainer}>
              <div className={styles.title}>关于Shoprise</div>
              <p>
                Shoprise是精心打造的独立站建站SaaS服务系统，在中国电商独立站出海潮的时代大背景下，立足于中国卖家的核心需求，着眼于海外市场的风云变幻，乘中国制造体系、商务模式成熟双红利之东风，积极帮助中国卖家抢占海外市场，与用户时刻保持沟通，认真聆听用户的声音，快速迭代满足用户的真切需求。Shoprise成立之初便确定了自身的使命所在——赋能出海电子商务，助力中国制造拥抱全球市场。
              </p>
              <p>
                我们的团队横跨福州、成都、深圳三座城市，拥有强大的支撑能力与广阔的商业格局。其中，福州研发中心的出海业务团队是跨境电商SaaS建站行业里老牌的服务提供商，其研发的SaaS系统从一开始就采用了全球最顶级的亚马逊服务器集群作为物理基础，采用的系统技术架构先进且稳定，具备强大的伸缩能力和容灾能力，自运行以来所建造的网站可用性无限趋近于100%，确保商户的交易始终稳定、安全地进行。
              </p>
              <p>
                当前，前后十年是出海独立站急速扩张的十年，这是出海独立站的黄金时代，我们将秉承初心，全力协助中国制造拥抱全球，创造更多属于我们自己的品牌，真正做到“立足中国，拥抱世界”！
              </p>
            </div>
            <div className={styles.imgContainer}>
              <img src={aboutImg1} alt="aboutImg1" />
              <img src={aboutImg2} alt="aboutImg2" />
              <img src={aboutImg3} alt="aboutImg3" />
            </div>
            <div className={styles.callUsContainer}>
              <div className={styles.title}>联系我们</div>
              <div className={styles.map}>
                {isBrowser && (
                  <APILoader akay="GTrnXa5hwXGwgQnTBG28SHBubErMKm3f">
                    <Map center={{ lng: 104.079455, lat: 30.546116 }}>
                      <Marker position={{ lng: 104.079455, lat: 30.546116 }} />
                    </Map>
                  </APILoader>
                )}
              </div>
              <div className={styles.inforContainer}>
                {/* <div className={styles.inforItem}>
                  <img src={phoneIcon} alt="phoneIcon" />
                  <div>公司电话：028-88888888</div>
                </div> */}
                <div className={styles.inforItem}>
                  <img src={mailIcon} alt="mailIcon" />
                  <div>公司邮箱：support@shoprise.com</div>
                </div>
                <div className={styles.inforItem}>
                  <img src={clockIcon} alt="clockIcon" />
                  <div>咨询时间：周一至周五，9:00AM-6:00PM</div>
                </div>
              </div>
            </div>
          </Col>
          {/* 移动端 */}
          <Col xs={24} sm={24} md={0}>
            <div className={styles.smallContainer}>
              <div className={styles.title}>关于Shoprise</div>
              <div className={styles.introGroup}>
                <div className={styles.introItem}>
                  <p>
                    Shoprise是精心打造的独立站建站SaaS服务系统，在中国电商独立站出海潮的时代大背景下，立足于中国卖家的核心需求，着眼于海外市场的风云变幻，乘中国制造体系、商务模式成熟双红利之东风，积极帮助中国卖家抢占海外市场，与用户时刻保持沟通，认真聆听用户的声音，快速迭代满足用户的真切需求。Shoprise成立之初便确定了自身的使命所在——赋能出海电子商务，助力中国制造拥抱全球市场。
                  </p>
                  <img src={aboutImg1} alt="aboutImg1" />
                </div>
                <div className={styles.introItem}>
                  <p>
                    我们的团队横跨福州、成都、深圳三座城市，拥有强大的支撑能力与广阔的商业格局。其中，福州研发中心的出海业务团队是跨境电商SaaS建站行业里老牌的服务提供商，其研发的SaaS系统从一开始就采用了全球最顶级的亚马逊服务器集群作为物理基础，采用的系统技术架构先进且稳定，具备强大的伸缩能力和容灾能力，自运行以来所建造的网站可用性无限趋近于100%，确保商户的交易始终稳定、安全地进行。
                  </p>
                  <img src={aboutImg2} alt="aboutImg2" />
                </div>
                <div className={styles.introItem}>
                  <p>
                    当前，前后十年是出海独立站急速扩张的十年，这是出海独立站的黄金时代，我们将秉承初心，全力协助中国制造拥抱全球，创造更多属于我们自己的品牌，真正做到“立足中国，拥抱世界”！
                  </p>
                  <img src={aboutImg3} alt="aboutImg3" />
                </div>
              </div>
              <div className={styles.title}>联系我们</div>
              <div className={styles.mapBox}>
                {isBrowser && (
                  <APILoader akay="GTrnXa5hwXGwgQnTBG28SHBubErMKm3f">
                    <Map
                      center={{ lng: 104.079455, lat: 30.546116 }}
                      style={{ borderRadius: "10px" }}
                    >
                      <Marker position={{ lng: 104.079455, lat: 30.546116 }} />
                    </Map>
                  </APILoader>
                )}
              </div>
              <div className={styles.inforContainer}>
                {/* <div className={styles.inforItem}>
                  <img src={phoneIcon} alt="phoneIcon" />
                  <div>
                    公司电话：
                    <br />
                    028-88888888
                  </div>
                </div> */}
                <div className={styles.inforItem}>
                  <img src={mailIcon} alt="mailIcon" />
                  <div>
                    公司邮箱：
                    <br />
                    support@shoprise.com
                  </div>
                </div>
                <div className={styles.inforItem}>
                  <img src={clockIcon} alt="clockIcon" />
                  <div>
                    咨询时间：
                    <br />
                    周一至周五，9:00AM-6:00PM
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.typePhoneNumber}>
        <picture>
          <source media="(min-width: 960px)" srcSet={typePhoneNumberPcImg} />
          <source media="(max-width: 960px)" srcSet={typePhoneNumberPhoneImg} />
          <img
            src={pcImg}
            alt={typePhoneNumberPcImg}
            style={{ width: "100%" }}
          />
        </picture>
        {/* <div className={styles.inputContainer}>
          <div className={styles.leftBox}>
            <input type="text" placeholder="请输入您的手机号" />
          </div>
          <div className={styles.rightBox}>
            <button onClick={handleLogin}>立享30天免费使用</button>
          </div>
        </div> */}

          <Button
            className={styles.primaryButton}
            shape="round"
            onClick={handleRegister}
            style={{ border: "none" }}
          >
            立享30天免费使用
          </Button>
      </div>
      <Footer />
    </div>
  );
};

export default AboutMePage;
